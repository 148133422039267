import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import NavigationBar from './sideBarMenu';
import '../styles/style.css';
import '../styles/Responsive.css';
import '../styles/style_bundle.css';
import ButtonGroup from './buttonGroup';
import MyDocuments from './myDocuments';
import NavigationNames from "./navigationNames";
import { refreshTokenAction } from '../../actions/refreshTokenAction';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Statistics from './statistics';
import { StatisticId } from '../../actions/statisticsId';
import Rename from './rename';
export default function Index() {
    const dispatch = useDispatch();
    const getUserInformationReducer = useSelector(state => state.getUserInformationReducer); 
    const refreshTokenReducer = useSelector(state => state.refreshTokenReducer);
    const newPdfRedux = useSelector(state => state.newPdfRedux);
    const [statisticsFlag, setStatisticsFlag] = useState(false);
    const statisticIdReducer = useSelector(state => state.StatisticIdReducer);
    const DeleteMoreThanOneDocumentReducer = useSelector(state => state.DeleteMoreThanOneDocumentReducer);
    const ShowModalFlagReducer = useSelector(state => state.ShowModalFlagReducer);
    const [renameDocumentActive, setRenameDocumentActive] = useState(false);

    useEffect(() => {
        dispatch(StatisticId(null, false));
        dispatch(refreshTokenAction(JSON.parse(localStorage.getItem('AccessToken')).data.refreshToken));
    }, []);

    useEffect(() => {
        if (statisticIdReducer && statisticIdReducer.data) {
            setStatisticsFlag(true);
        }
    }, [statisticIdReducer]);

    useEffect(() => {
        if (getUserInformationReducer && getUserInformationReducer.data && getUserInformationReducer.data.isSuccessful) {
            localStorage.setItem('user', JSON.stringify(getUserInformationReducer.data.data));
        }
    }, [getUserInformationReducer])

    useEffect(() => {
        if(ShowModalFlagReducer && ShowModalFlagReducer.show === "edit" && ShowModalFlagReducer.data === true){ 
            setRenameDocumentActive(true);
        }
    }, [ShowModalFlagReducer])

    return (
        <div className="mainDivRsT">
                <NavigationBar />
            <section className='app-wrapper w-100 mb-3'> 
                <ToastContainer />
                    <div className='currentMenuItem'>
                        <NavigationNames MenuName="My Documents" />
                    </div>
               
                    <div className="row card borderNoneCard listPage-mainDiv">
                        <ButtonGroup typeOfButtons="myDocuments" />

                    
                        <div className="tab-content mt-4 top-border-table">
                            <div className="tab-pane active" id="mydocuments">
                                < MyDocuments />
                            </div>
                        </div>
                    </div>              
            </section>
            <>
                {renameDocumentActive && <Rename show="true" />}
                {statisticsFlag && <Statistics id={statisticIdReducer.data} />}
            </>
        </div>
    );
}
//<Rename show="true" />