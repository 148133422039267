import React, { Component, useEffect } from 'react'
import {
    CDBSidebar,
    CDBSidebarContent,
    CDBSidebarFooter,
    CDBSidebarHeader,
    CDBSidebarMenu,
    CDBSidebarMenuItem,
} from 'cdbreact';
import '../styles/sideBar.css';
import { useState } from 'react';
import booby from '../../images/Logo_removebg.png';
import { NavLink, Link } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.min.css';
import GiveFeedback from './giveFeedback';
import CreditAccount from './creditAccount';
import { useSelector, useDispatch } from 'react-redux';
import { getBalance } from "../../actions/getBalanceAction";
import Spinner from 'react-bootstrap/Spinner';
import { SplashScreen } from '../../actions/splashScreen';
import { ShowModalFlag } from '../../actions/showModalFlag';
export default function NavigationBar() {
    const [activeComponent, setActiveComponent] = useState(false);
    const [activeComponent2, setActiveComponent2] = useState(false);
    const [balanceFlag, setBalanceFlag] = useState(false);
    const dispatch = useDispatch();
    const getBalanceRedux = useSelector(state => state.getBalanceRedux);
    const SplashScreenReducer = useSelector(state => state.SplashScreenReducer);
    const newPdfRedux = useSelector(state => state.newPdfRedux);
    //const [isLoading, setIsloading] = useState(false);
    useEffect(() => {
        if (getBalanceRedux && getBalanceRedux.data && getBalanceRedux.data.isSuccessful && getBalanceRedux.data.data.balance === 0) {
            setBalanceFlag(true);
        }
    }, [getBalanceRedux]);

    useEffect(() => {
        dispatch(getBalance());
    }, []);

    const handleClick = () => {
        setActiveComponent(true);
        dispatch(ShowModalFlag("feedback"));
    }
    const handleClick2 = () => {
        setActiveComponent2(true);
        dispatch(ShowModalFlag("buycrypto"));
    }

    useEffect(() => {
        
        if (newPdfRedux && newPdfRedux.data && newPdfRedux.data.success == true) {
          dispatch(SplashScreen(false)); 
        } else if (newPdfRedux && newPdfRedux.data && newPdfRedux.data.error) {
            dispatch(SplashScreen(false)); 
        }
    }, [newPdfRedux])

    /*useEffect(() => {
        if (SplashScreenReducer && SplashScreenReducer.data) {
            setIsloading(true);
        }
    },[SplashScreenReducer])
    */
    var toggleClick = () => {
        var sidebarToggled = document.querySelectorAll(".pro-sidebar:not(.toggled)");
        var appWrapper = document.getElementsByClassName("app-wrapper");
        if (sidebarToggled && sidebarToggled.length > 0) {
            appWrapper[0].classList.add("toggled");
        } else {
            appWrapper[0].classList.remove("toggled");

        }
    }

    useEffect(() => {
        window.addEventListener("resize", () => {
            var appWrapper = document.getElementsByClassName("app-wrapper");
            appWrapper[0].classList.remove("toggled");
        });
    },[])

    return (
        <div className='bg-dark app-sidebar' style={{ display: 'flex', overflow: 'scroll initial' }}>
            <CDBSidebar backgroundColor="#F6F6F6" textColor="#7E8299" maxWidth="220px">
                <CDBSidebarHeader prefix={<i onClick={toggleClick} className="fa fa-angles-left fa-large"></i>}>
                    
                    <Link to="/index" className="text-decoration-none" style={{ color: 'inherit', textAlign: 'center' }}>
                        <img src={booby} alt="brand" width={135} ></img>
                    </Link>
                </CDBSidebarHeader>

                <CDBSidebarContent className="sidebar-content">
                    <CDBSidebarMenu>
                        <NavLink exact="true" to="/index" activeclassname="active">
                            <CDBSidebarMenuItem style={{ backgroundColor: 'inherit' }} icon="file-lines">My Documents</CDBSidebarMenuItem>
                        </NavLink>
                        <NavLink exact="true" to="/sharedWithMePage" activeclassname="active">
                            <CDBSidebarMenuItem style={{ backgroundColor: 'inherit' }} icon="users">Shared With Me</CDBSidebarMenuItem>
                        </NavLink>
                        <NavLink exact="true" to="/recycleBinPage" activeclassname="active">
                            <CDBSidebarMenuItem style={{ backgroundColor: 'inherit' }} icon="trash">Recycle Bin</CDBSidebarMenuItem>
                        </NavLink>
                        <>
                        { SplashScreenReducer && SplashScreenReducer.data === true &&
                                (<div className="badge itc bg-success badge-success text-white" style={{ marginLeft:'2rem', height:'32px' }} >                                
                                <CDBSidebarMenuItem style={{ display: 'flex' }}>
                                    <Spinner animation="border" className="text-white" style={{ marginTop: '0.5rem', width: "16px", height: "16px", marginRight: '1rem' }} variant="info" />
                                    <span style={{height:'32px', fontSize:'.75rem', lineHeight:'32px'}}>
                                        Processing
                                    </span>
                                </CDBSidebarMenuItem>
                            </div>)
                        }
                        </>
                    </CDBSidebarMenu>
                </CDBSidebarContent>

                <CDBSidebarFooter>
                    {activeComponent2 && <CreditAccount onClose={e=> setActiveComponent2(false)} show="true" />}
                    <CDBSidebarMenuItem onClick={handleClick2} className="bg-success text-white rounded-some" icon="brands fa-bitcoin">
                        <span className="text-white font-weight-bold" style={{'fontWeight':'bold', 'fontSize':'1rem'}}>Buy with Crypto</span>                        
                    </CDBSidebarMenuItem>
                    <CDBSidebarMenuItem onClick={handleClick} icon="paper-plane" style={{ 'fontSize': '0.85rem' }}>Send us Feedback</CDBSidebarMenuItem>
                    {false && <NavLink exact="true" to="/hero404" target="_blank">
                        <CDBSidebarMenuItem icon="user-pen">Subscription</CDBSidebarMenuItem>
                    </NavLink>}
                    <div className="sidebar-footer-padding"
                        style={{
                            paddingBottom: '20px',
                            textAlign: 'center',
                        }}
                    >
                        {balanceFlag ? <div>You have no credits</div> : ''}
                    </div>
                </CDBSidebarFooter>
                {activeComponent &&  <GiveFeedback show="true"/>}
            </CDBSidebar>
        </div>
    );

}
