import axios from 'axios';

export const FORGETPASSWORD_SUCCESS = 'FORGETPASSWORD_SUCCESS';
export const FORGETPASSWORD_RESET = 'FORGETPASSWORD_RESET';
export const FORGETPASSWORD_FAILURE = 'FORGETPASSWORD_FAILURE';


export const ForgetPasswordAction = (Email) => async (dispatch) => {
    debugger;
    if (!Email || Email === "") {
        dispatch({
            type: FORGETPASSWORD_RESET,
            payload: null,
        });
    } else {
        try {
            const forgotPassword = {
                email: Email,
            }
            const response = await axios.post('/api/Auth/ForgotPassword', forgotPassword, JSON);

            dispatch({
                type: FORGETPASSWORD_SUCCESS,
                payload: response.data,
            });

        } catch (errors) {
            dispatch({
                type: FORGETPASSWORD_FAILURE,
                payload: errors.response.data.error.errors[0],
            });

        };
    }
}
