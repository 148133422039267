/* eslint-disable no-undef */
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector,  } from 'react-redux'
import { useNavigate, Link } from "react-router-dom";
import '../styles/login.css';
import { loginAction } from '../../actions/AuthAction'
import { useCookies } from 'react-cookie';
import { getUserInformation } from '../../actions/getUserInformation';
import booby from '../../images/Logo_removebg.png';
import ReCAPTCHA from "react-google-recaptcha"
export function Login() {
    const navigate = useNavigate();
    const [cookies, setCookies] = useCookies(['user']);
    const [email, setEmail] = useState(cookies.Email);
    const [password, setPassword] = useState(cookies.Password);
    const dispatch = useDispatch();
    const loginRedux = useSelector(state => state.loginRedux)
    const [rememberMe, setRememberMe] = useState(false);
    const catpchaRef = useRef(null);

    const handleSubmit = (event) => {
        event.preventDefault();
        const recaptchaToken = catpchaRef.current.getValue();
        //captchaRef.current.reset();
        dispatch(loginAction(email, password, recaptchaToken));
    }

    const handleUsernameChange = (event) => {
        event.preventDefault();
        setEmail(event.target.value);
    };

    useEffect(() => {
        if (loginRedux) {
            if (loginRedux.isAuthenticated) {
                if (rememberMe) {
                    setCookies('Email', email, { path: '/' });
                }
                localStorage.setItem('AccessToken', JSON.stringify(loginRedux.token));
                dispatch(getUserInformation());
                navigate("/index");
            }
          
            if(loginRedux.error) {
                setPassword('');
            }
        }
    }, [loginRedux]);

    const handlePasswordChange = (event) => {
        event.preventDefault();
        setPassword(event.target.value);
    };

    const onChangeRememberMe = (event) => {
        setRememberMe(event.target.checked);
    }

    return (
        <div className="row AuthTL">
            <div className="col-md-4 d-flex justify-content-center">
                <section className="">             
                    <form id="account" method="post" onSubmit={handleSubmit}>
                        <img src={booby} alt="Bymmy App" style={{ 'float': 'right', 'marginRight': '-25px' }}></img>
                        <h2 style={{'fontWeight':'bold'}}>Log In</h2>
                        <hr />
                        <div className="pb-3">
                            <label className="form-label">Username</label>
                            <input type="text" value={email} onChange={handleUsernameChange} className="form-control" required={true} aria-required="true" autoComplete="true"/>
                        </div>
                        <div className="pb-3">
                            <label className="form-label">Password</label>
                            <input type="password" value={password} onChange={handlePasswordChange} className="form-control" required={true}  aria-required="true" autoComplete="new-password" />
                        </div>
                        <div>
                            {loginRedux.error && loginRedux.error !== null > 0 && Object.values(loginRedux.error).map(errors => (
                            <div className='error-badge' >{errors}</div>
                            ))}
                        </div>
                        <div className="pt-2 pb-2">
                            <div className="checkbox">
                                <label className="form-label" style={{ 'fontSize': '13px' }}>
                                    <input type='checkbox' checked={rememberMe} onChange={onChangeRememberMe} className="form-check-input" /> Remember Me
                                </label>
                            </div>
                        </div>
                        <div className="pb-4" style={{'textAlign':'center'}}>
                            <ReCAPTCHA
                                sitekey={process.env.REACT_APP_SITE_KEY}
                                ref={catpchaRef}
                            />
                        </div>
                        <div className="pb-4">
                            <button id="login-submit" type="submit" className="w-100 btn btn-lg default-col btn-spf-grn" style={{'fontWeight':'bold'}} disabled={loginRedux.busy}>
                                Sign In {' '}
                                {loginRedux.busy &&  
                                    <>
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        <span className="visually-hidden">Loading...</span>
                                    </>
                                }
                            </button>

                              
                               
                        </div>
                      
                        <div>
                            <p style={{'fontSize':'13px'}}>
                                Forget your password?  <Link to="/ForgetPassword">Reset Password</Link>
                                <br/>
                                Don't have an account? <Link to="/SignIn">Sign Up</Link>
                                <br/>
                                Didn't receive a confirmation email? <Link to="/reSendMailConfirmation">Send again</Link>
                            </p>
                        </div>
                           
                    </form>
                </section>
            </div>

        </div>
    );
}
export default Login;




