import React, { Component, useState, useEffect } from 'react';
import '../styles/login.css';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { SignInAction, SIGNIN_RESET } from '../../actions/SignInAction';
import $ from 'jquery';
import classnames from 'classnames';
import booby from '../../images/Logo_removebg.png';
export default function SignIn(props){

    //const [email, setEmail] = useState('');
    //const [password, setPassword] = useState('');
    //const [validationPassword, setValidationPassword] = useState('');
    //const [errorMessage, setErrorMessage] = useState('');
    //const [error, setError] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const signInRedux = useSelector(state => state.signInRedux)

    const [input, setInput] = useState({
        email: '',
        password: '',
        confirmPassword: '',
        firstName: '',
        lastName:''
    });

    const [error, setError] = useState({
        email: '',
        password: '',
        confirmPassword: '',
        firstName: '',
        lastName:'',
    });

    useEffect(() => {
        if (signInRedux) {
            if (signInRedux.success) {
                if (signInRedux.navigateConfirmFlag) {
                    navigate("/registerNotConfirmation",
                        {
                            state: {
                                email: input.email,
                            }
                        }); 
                    dispatch({ type: SIGNIN_RESET });
                }
            } else if (signInRedux.error) {
                //{ signInRedux.error && signInRedux.error.map(item => (<span className='errMsg' >{item}</span>)) }
                signInRedux.error[0].map(item => {
                    if (item.indexOf("Password") > -1) {
                        setError(prev => ({
                            ...prev,
                            "password": item
                        }));
                     
                    }
                    if (item.indexOf("Email") > -1) { 
                        setError(prev => ({
                            ...prev,
                            "email": item
                        }));  
                    }
                });


                //setPassword('');
                //setValidationPassword('');
                
            }
        }
    }, [signInRedux]);

    const handleSubmit = (event) => {
        event.preventDefault();        
        if (isValidEmail(input.email) && isValidPassword(input.password)) {
            dispatch(SignInAction(input.email, input.password, input.confirmPassword, input.firstName, input.lastName));
            setInput(prev => ({
                ...prev,
                email: ""
            }));
        }
       

    };

    function isValidEmail(email) {
        if (!(/\S+@\S+\.\S+/.test(email))) {
            setError(prev => {
                const stateObj = { ...prev, ["email"]: "The Email Field is not a valid e-mail address" }
                return stateObj;
            })
        }
        return /\S+@\S+\.\S+/.test(email);
    }

    function isValidPassword(password) {
        const passwordInputValue = password;

        const uppercaseRegExp = /(?=.*?[A-Z])/;
        const lowercaseRegExp = /(?=.*?[a-z])/;
        const digitsRegExp = /(?=.*?[0-9])/;
        const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
        const minLengthRegExp = /.{8,}/;

        const passwordLength = passwordInputValue.length;
        const uppercasePassword = uppercaseRegExp.test(passwordInputValue);
        const lowercasePassword = lowercaseRegExp.test(passwordInputValue);
        const digitsPassword = digitsRegExp.test(passwordInputValue);
        const specialCharPassword = specialCharRegExp.test(passwordInputValue);
        const minLengthPassword = minLengthRegExp.test(passwordInputValue);

        if (passwordLength === 0) {
            setError(prev => {
                const stateObj = { ...prev, ["password"]: "Password is empty" }
                return stateObj;
            })
            return false;
        } else if (!uppercasePassword) {
            setError(prev => {
                const stateObj = { ...prev, ["password"]: "At least one Uppercase['A']-['Z']" }
                return stateObj;
            })
            //document.getElementsByName('password').style.borderColor = 'red';
            return false;
        } else if (!lowercasePassword) {
            setError(prev => {
                const stateObj = { ...prev, ["password"]: "At least one Lowercase" }
                return stateObj;
            })
            return false;
        } else if (!digitsPassword) {
            setError(prev => {
                const stateObj = { ...prev, ["password"]: "At least one digit" }
                return stateObj;
            })
            return false;
        } else if (!specialCharPassword) {
            setError(prev => {
                const stateObj = { ...prev, ["password"]: "At least one Special Characters" }
                return stateObj;
            })
            return false;
        } else if (!minLengthPassword) {
            setError(prev => {
                const stateObj = { ...prev, ["password"]: "At least minumum 8 characters" }
                return stateObj;
            })
            return false;
        }
        //document.getElementsByName('password').style.borderColor = 'green';
        return true;
    }

    const InputChange = (event) => {
        const { name, value } = event.target;
            setInput(prev => ({
            ...prev,
            [name]: value
        }));
        validateInput(event);
    }

    const validateInput = (event) => {
        let { name, value } = event.target;
        setError(prev => {
            const stateObj = { ...prev, [name]: "" };

            switch (name) {
                case "email":
                    if (!value) {
                        stateObj[name] = "Please enter Email.";

                    }
                  
                    break;

                case "password":
                    if (!value) {
                        stateObj[name] = "Please enter Password.";
                    } else if (input.confirmPassword && value !== input.confirmPassword) {
                        stateObj["confirmPassword"] = " Password and Confirm Password does not match";
                    } else {
                        stateObj["confirmPassword"] = input.confirmPassword ? "" : error.confirmPassword;
                    }
                    break;
                case "confirmPassword":
                    if (!value) {
                        stateObj[name] = "Please enter Confirm Password.";
                    } else if (input.password && value !== input.password) {
                        stateObj[name] = "Password and Confirm Password does not match.";
                    }
                    break;
                default:
                    break;
            }
            return stateObj;
        })
    }
        return (
            <div className="row AuthTL">
                <div className= "col-md-4 d-flex justify-content-center">
                    <section>
                        
                        <form id="registerForm" method="post" onSubmit={handleSubmit}>
                            
                            <img src={booby} alt="Bymmy App" style={{ 'float': 'right', 'marginRight': '-25px' }}></img>
                            <h2 style={{ 'fontWeight': 'bold' }}>Sign Up</h2>
                            <hr />
                            {signInRedux.success && <span style={{ fontSize: '16px', color: 'green' }} >registration was successful</span>}
                                <div className="pb-3">
                                    <label className="form-label">Email</label>
                                    <input type="text" name="email" value={input.email} onChange={InputChange} className="form-control" required placeholder='Enter Email'/>
                                    {error && error.email && <span className='errMsg'> - {error.email}</span>}
                            </div>


                            <div className="pb-3">
                                <label className="form-label">Firstname</label>
                                <input type="text" name="firstName" value={input.firstName} onChange={InputChange} className="form-control" required placeholder='Enter Firstname' />
                                {error && error.firstname && <span className='errMsg'> - {error.firstName}</span>}
                            </div>
                            <div className="pb-3">
                                <label className="form-label">Lastname</label>
                                <input type="text" name="lastName" value={input.lastName} onChange={InputChange} className="form-control" required placeholder='Enter Lastname' />
                                {error && error.lastName && <span className='errMsg'> - {error.lastName}</span>}
                            </div>

                                <div className="pb-3">
                                    <label className="form-label">Password</label>
                                <input type="password" minLength="8" maxLength="100" name="password" placeholder='Enter Password' value={input.password} onChange={InputChange} className="form-control" required/>
                                {error && error.password && <span className='errMsg'> - {error.password}</span>}
                            </div>
                            <div className="pb-3">
                                <label className="form-label">Confirm Password</label>
                                <input type="password" minLength="8" maxLength="100" name="confirmPassword" placeholder='Enter Confirm Password' value={input.confirmPassword} onChange={InputChange} className="form-control" required />
                                {error && error.confirmPassword && <span className='errMsg'> - {error.confirmPassword}</span>}
                            </div>
                           
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="terms-of-service-legal" required></input>
                                    <label className="form-check-label" htmlFor="terms-of-service-legal">
                                        <div className="ml-10"><small>
                                            I agree to the
                                            <Link to="/terms_of_service"> Terms & Conditions </Link>
                                            and have read the
                                            <Link to="/privacy_policy"> Privacy Policy </Link>
                                            </small>
                                        </div>
                                    </label>

                            </div>
                            

                            <div className="pb-4">
                                <button id="registerSubmit" style={{ 'fontWeight': 'bold' }} type="submit" className="w-100 btn btn-lg btn-spf-grn">Register</button>
                            </div>  
                            <div>
                                <p style={{ 'fontSize': '13px' }}>
                                    Didn't receive a confirmation email? <Link to="/reSendMailConfirmation">Send again</Link>        


                                    </p>
                            </div>
                        </form>
                    </section>
                </div>

            </div>
        );
    
}
// {signInRedux.error && signInRedux.error.errors.response.data.errors.map(item => (<span style="font-size: 16px;color: red;">{item}</span>))}