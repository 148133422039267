import '../styles/style.css';
import { Link } from 'react-router-dom';
import React, { useMemo, useState, useEffect } from 'react';
import FilterComponent from './filterComponent';
import DataTable, { createTheme } from "react-data-table-component";
import { useSelector, useDispatch } from 'react-redux';
import { DeleteDocumentAction } from '../../actions/deleteDocument';
import { IndexAction } from '../../actions/IndexAction';
import { ButtonActionsFlag } from '../../actions/ButtonActionsFlag';
import pdf from '../../images/pdf-ref2.png'
import { StatisticId } from '../../actions/statisticsId';
import { ShowModalFlag } from '../../actions/showModalFlag';
import { EditFileAction } from '../../actions/EditFileAction';
createTheme("solarized", {
    text: {
        primary: "#268bd2",
        secondary: "#2aa198"
    },
    background: {
        default: "#002b36"
    },
    context: {
        background: "#cb4b16",
        text: "#FFFFFF"
    },
    divider: {
        default: "#073642"
    },
    action: {
        button: "rgba(0,0,0,.54)",
        hover: "rgba(0,0,0,.08)",
        disabled: "rgba(0,0,0,.12)"
    }
});

export default function MyDocuments() {
    const [resetData, setResetData] = useState(null);
    const [myData, setMyData] = useState([]);
    const dispatch = useDispatch();
    const IndexReducer = useSelector(state => state.IndexReducer);
    const DeleteDocumentReducer = useSelector(state => state.DeleteDocumentReducer);
    const EditDocumentReducer = useSelector(state => state.EditDocumentPropReducer);
    const DeleteMoreThanOneDocumentReducer = useSelector(state => state.DeleteMoreThanOneDocumentReducer);
    const [renameDocumentActive, setRenameDocumentActive] = useState(false);
    const [flag, setFlag] = useState(false);

    const newPdfSuccess = useSelector(state => state.newPdfRedux.last);

    useEffect(() => {
        if (!flag) {
            dispatch(IndexAction());
            setFlag(true);
        }
    }, []);


    useEffect(() => {
        if (newPdfSuccess == "SUCCESS") {
            dispatch(IndexAction());
        }
    }, [newPdfSuccess]);


    useEffect(() => {
        dispatch(IndexAction());
    }, [EditDocumentReducer]);

    useEffect(() => {

        setMyData([]);
        if (IndexReducer && IndexReducer.data && IndexReducer.data.isSuccessful) {
            setMyData(myData => [...myData, ...IndexReducer.data.data.rt]);
            //localStorage.setItem('myDocuments', JSON.stringify(IndexReducer.data.data.rt));
        }
    }, [IndexReducer]);

    useEffect(() => {
        if (DeleteDocumentReducer && DeleteDocumentReducer.data.isSuccessful) {
            dispatch(IndexAction());
        }
    }, [DeleteDocumentReducer]);

    const deleteDocument = (event) => {
        event.preventDefault();
        dispatch(DeleteDocumentAction(parseInt(event.currentTarget.id)))

    }

    useEffect(() => {
        if (DeleteMoreThanOneDocumentReducer && DeleteMoreThanOneDocumentReducer.data && DeleteMoreThanOneDocumentReducer.data.isSuccessful) {
            setResetData(DeleteMoreThanOneDocumentReducer.count);
        }
    }, [DeleteMoreThanOneDocumentReducer]);

    const onClickChanges = (data) => {
        if (resetData && resetData !== null) {
            data.selectedRows = data.selectedRows.slice(0, (-1 * resetData));
            data.selectedCount = data.selectedCount - resetData;
            setResetData(null);
        }
        if (data) {
            dispatch(ButtonActionsFlag(data));
        }
    }

    const onClickStatistics = (id, name) => {
        dispatch(StatisticId(id, name, true));
    }

    const Header1 = ({ n }) => <span><b>{n}</b></span>

    const renameClickEvent = (row) => {
        dispatch(ShowModalFlag("edit"));
        dispatch(EditFileAction(row))
    }

    const columns = [
        {
            name: "Actions",
            selector: (row) => (

                <div className="btn-group-actions">
                    <Link className="text-success btn btn-sm btn-success text-white m-1" to={{
                        pathname: `/index/viewer/${row.id}`
                    }} state={{ id: row.id }} title="View">
                        <i style={{ color: 'white' }} className="fa-regular fa-eye"></i>
                    </Link>

                    <Link className="text-success btn btn-sm btn-primary text-white m-1" onClick={() => { renameClickEvent(row.model) }}
                       title="Edit">
                        <i style={{ color: 'white' }} className="fa-solid fa-pencil"></i>
                    </Link>

                    <div className="text-muted btn btn-sm btn-warning text-white m-1" onClick={() => { onClickStatistics(row.id, row.model.name) }} title="Statistics">
                        <i style={{ color: 'white' }} className="fa-regular fa-chart-bar"></i>
                    </div>
                    <Link className="text-primary btn btn-sm btn-info text-white m-1" to={{
                        pathname: `/index/share/${row.id}`,
                    }} state={{ id: row.id, data: row }}
                        title="Share">
                        <i className="fa fa-users"></i>
                    </Link>
                    <Link onClick={deleteDocument} id={row.id} className="text-danger btn btn-sm btn-danger text-white m-1" to="#" title="Delete">
                        <i className="fa-solid fa-trash-can"></i>
                    </Link>
                </div>

            ),
            width: "230px",
        }, {
            name: <Header1 n={'Name'} />,
            selector: (row) => row['model']['name'],
            cell: row => <div><img src={pdf} alt="pdf" width={25} height={25}></img>&nbsp;{row['model']['name']}</div>,
            sortable: true,
            minWidth: "200px",
        },
        {
            name: <Header1 n={'Description'} />,
            selector: (row) => row['model']['description'],
            sortable: true,
            width: "25%",
            hide: 'sm',
            //grow:1
        },
        {
            name: <Header1 n={'Views'} />,
            selector: (row) => row.count,
            sortable: true,
            width: "80px",
            center:true

        }, {
            name: <Header1 n={'Viewers'} />,
            selector: (row) => row.countReader,
            sortable: true,
            width: "95px",
            center: true
        }, {
            name: <Header1 n={'# Pages'} />,
            selector: (row) => row['model']['totalPages'],
            sortable: true,
            width: "100px",
            center: true
        },
        {
            name: "Create",
            selector: (row) => (
                row.model.createdAt.split('.')[0].replace('T', ' ')
            ),
            sortable: true,
            hide: 'sm',
            width: "180px",
        },
        {
            name: "Update",
            selector: (row) => (
                row.model.modifiedAt.split('.')[0].replace('T', ' ')
            ),
            sortable: true,
            hide: 'sm',
            width: "180px",
        },
    ];

    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    const filteredItems = myData.filter(
        item =>
            JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
    );

    const subHeaderComponent = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        };

        return (
            <>
                <hr/>
                <FilterComponent
                    onFilter={e => setFilterText(e.target.value)}
                    onClear={handleClear}
                    filterText={filterText}
                />
            </>
        );
    }, [filterText, resetPaginationToggle]);

    const customPagination = {
        selectAllRowsItem : true,
        selectAllRowsItemText : "All",
    }
    const tableCustomStyles = {

        headRow: {
            style: {
                color: '#223336',
                backgroundColor: '#e7eef0'
            },
        },
    }
    return (
        <div className="App mb-5">
            <DataTable
                columns={columns}
                customStyles={tableCustomStyles}
                data={filteredItems}
                pagination
                paginationComponentOptions={customPagination}
                highlightOnHover
                striped
                
                defaultSortFieldId={7}
                defaultSortAsc={false}
                paginationPerPage={30}
                subHeader
                subHeaderComponent={subHeaderComponent}
                selectableRows
                selectableRowsOnClick
                onSelectedRowsChange={onClickChanges}
            />
        </div>
    );
}
