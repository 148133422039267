import React from 'react';
import MassShareDocument from './massShareDocument';
import { fetchEditSharePost } from '../../actions/fetchEditSharePost';
import { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, Link, useLocation, useParams } from "react-router-dom";
import DatePickerComponent from './datePicker';
import NavigationBar from './sideBarMenu';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { SharedFromMe } from '../../actions/sharedFromMe';
import '../styles/style.css';
import DataTable, { createTheme } from "react-data-table-component";
import FilterComponent from './filterComponent';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ShowModalFlag } from '../../actions/showModalFlag';
import { IsAuthorizeDocumentAction } from '../../actions/isAuthorizeDocument';
import { ViewerAction } from '../../actions/viewer';
export default function Share() {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    console.log(location.state);
    const { id } = location.state !== null ? location.state : { id: parseInt(window.location.pathname.split("/").slice(-1)[0]) };
    const { testvalue } = useParams();
    const [massShareDocumentActive, setMassShareDocumentActive] = useState(false);
    const [email_address, setEmail_address] = useState('');
    var date = new Date();
    const dateString = `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')}/${date.getFullYear()} ${date.getHours() % 12}:${date.getMinutes().toString().padStart(2, '0')} ${date.getHours() < 12 ? 'AM' : 'PM'}`;
    const [multiExpireDate, setMultiExpireDate] = useState(dateString);
    const [allow_download_multi, setAllow_download_multi] = useState(false);
    const sharedFromMeReducer = useSelector(state => state.SharedFromMeReducer);
    const [sharedTableFlag, setSharedTableFlag] = useState(false);
    const [sharedTableData, setSharedTableData] = useState([]);
    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const fetchEditSharePostReducer = useSelector(state => state.FetchEditSharePostReducer);
    const fetchEditShareMultiPostReducer = useSelector(state => state.FetchEditShareMultiPostReducer);
    const viewerReducer = useSelector(state => state.viewerReducer);
    const [document, setDocument] = useState(location.state && location.state.data.model.name);
    const [permission, setPermission] = useState(true);
    const IsAuthorizeDocumentReducer = useSelector(state => state.IsAuthorizeDocumentReducer);
    const [errorMessage, setErrorMessage] = useState("");
    useEffect(() => {
        dispatch(IsAuthorizeDocumentAction(id));
    }, []);

    useEffect(() => {
        if (viewerReducer && viewerReducer.data && viewerReducer.data.isSuccessful) {
            setDocument(viewerReducer.data.data);
        }
    }, [viewerReducer])
    useEffect(() => {
        if (IsAuthorizeDocumentReducer && IsAuthorizeDocumentReducer.data && IsAuthorizeDocumentReducer.data.isSuccessful
            === true) {
            dispatch(ViewerAction(id));
            dispatch(SharedFromMe(id));
        } else if (IsAuthorizeDocumentReducer && IsAuthorizeDocumentReducer.error && IsAuthorizeDocumentReducer.error.errors){
            setErrorMessage(IsAuthorizeDocumentReducer.error.errors[0]);
            setPermission(false);
        }
    }, [IsAuthorizeDocumentReducer])
    useEffect(() => {
        if (sharedFromMeReducer && sharedFromMeReducer.data && sharedFromMeReducer.data.isSuccessful == true) {
            setSharedTableFlag(true);
            setSharedTableData(sharedFromMeReducer.data.data);
        }

    }, [sharedFromMeReducer]);

    const importList = (event) => {
        setMassShareDocumentActive(true);
        dispatch(ShowModalFlag("MultiShare"));
    }

    const shareDocument = (event) => {
        event.preventDefault();
        dispatch(fetchEditSharePost(id, email_address, multiExpireDate, allow_download_multi));
    }

    useEffect(() => {
        if (fetchEditSharePostReducer && fetchEditSharePostReducer.data && fetchEditSharePostReducer.data.isSuccessful === true) {
            dispatch(SharedFromMe(id));
        }
    }, [fetchEditSharePostReducer]);
  
    useEffect(() => {
        if (fetchEditShareMultiPostReducer && fetchEditShareMultiPostReducer.data && fetchEditShareMultiPostReducer.data.isSuccessful === true) {
            dispatch(SharedFromMe(id));
        }
    }, [fetchEditShareMultiPostReducer]);

    const changeEmailAddress = (event) => {
        event.preventDefault();
        setEmail_address(event.target.value);
    }

    const changeAllowDownload = (event) => {
        //event.preventDefault();
        setAllow_download_multi(!allow_download_multi,);
    }

    const changeMultiExpireDate = (date) => {
        setMultiExpireDate(date);
    }


    const columns = [
        {
            name: "User Name",
            selector: (row) => row.readedBy,
            sortable: true,

        },
        {
            name: "Total View",
            selector: (row) => row.totalview,
            sortable: true,

        },
        {
            name: "Created At",
            selector: (row) => (
                row.createdAtRes.split('.')[0].replace('T', ' ').replace('Z', ' ')
            ),
            sortable: true,
        },
        {
            name: "Expire At",
            selector: (row) => (
                row.expireDate.split('.')[0].replace('T', ' ').replace('Z', ' ')
            ),
            sortable: true,

        },   
    ]

    const filteredItems = sharedTableData.filter(
        item =>
            JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
    );

    const subHeaderComponent = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        };

        return (
            <FilterComponent
                onFilter={e => setFilterText(e.target.value)}
                onClear={handleClear}
                filterText={filterText}
            />
        );
    }, [filterText, resetPaginationToggle]);

    const customPagination = {
        selectAllRowsItem: true,
        selectAllRowsItemText: "All",
    }

    return (
        <div className="mainDivRsT" style={{ display: 'block' }}> 
            <ToastContainer />
            <NavigationBar /> 
            { permission === true ?
                <><section id="sharePage" style={{ 'marginRight': 0 }} className="app-wrapper pt-5 row">
                    <h5>{document && document.name}</h5>
                                       
                    <div className="col-md-5 col-xs-12">
                    <form>
                        
                        <legend className="fw-bold" style={{ marginBottom: '.25rem' }} >Share PDF</legend>
                        <hr />
                        <div style={{ textAlign: 'right' }} >
                            <div onClick={() => { importList() }} className="btn btn-sm btn-success">MultiShare</div>
                            {massShareDocumentActive === true && <MassShareDocument show="true" />}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="formFile" className="form-label">Email</label>
                            <input className="text-box single-line form-control" value={email_address} onChange={changeEmailAddress} placeholder="Enter email address" id="email_address" name="email_address" type="email" required></input>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" onChange={changeAllowDownload} value={allow_download_multi} type="checkbox" id="allow_download" name="allow_download"></input>
                            <label className="form-check-label" htmlFor="allow_download">
                                Allow Downloading <span className="text-muted"><small>Viewers can download your file.</small></span>
                            </label>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="formFile" className="form-label">Expire Date:</label>
                            <DatePickerComponent changeMultiExpireDate={changeMultiExpireDate} />
                        </div>
                        <div className="mb-3" style={{ textAlign: 'right' }}>

                                <button onClick={shareDocument} style={{ color: 'white' }} disabled={fetchEditSharePostReducer.busy} type="submit" className="btn btn-info sharenow" id="btnshare">
                                    <span className="spinner-border spinner-border-sm"  style={{ display: fetchEditSharePostReducer.busy ? 'inline-block':'none' }} role="status" aria-hidden="true"></span>
                                Share Document
                            </button>

                                <div id="loader5" className="alert" style={{ display: fetchEditSharePostReducer.busy ? 'block' : 'none' }}>
                                Sharing document, please wait...
                            </div>
                        </div>
                    </form>
                    </div>
                    <div className="col-md-5 col-xs-12">
                        <div className=" shareTable">
                        <div id="shareTable" className="card2">
                            <h5><strong>Collaborations</strong></h5>
                            <hr></hr>
                            {sharedTableFlag &&
                                <div className="myDocument">
                                    <DataTable
                                        columns={columns}
                                        data={filteredItems}
                                        pagination
                                        paginationComponentOptions={customPagination}
                                        highlightOnHover
                                        striped
                                        defaultSortFieldId={3}
                                        defaultSortAsc={false}
                                        subHeader
                                        subHeaderComponent={subHeaderComponent}
                                    />
                                </div>
                            }
                            </div>
                        </div>
                    </div>


                </section>
                </> :
                <div className="alert alert-danger d-flex align-items-center fw-bold app-wrapper mt-2 w-75" role="alert">
                    <i class="fa fa-solid fa-triangle-exclamation"></i>
                    {errorMessage === "" ?
                    <div>
                        &nbsp;You Don't Have Permission for Sharing PDF
                    </div>
                        :
                    <div>
                            &nbsp;{errorMessage}
                    </div>
                    }
                </div>
            }
        </div>
    )
}
/*
<div style={{height:'36px'}}>{' '}</div>
<h1>{location.state.data.model.name}</h1>
<div style={{ height: '12px' }}>{' '}</div>
*/